import { Redirect, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import { setAuthUser } from "../../reducers/AuthUser";

const AuthenticatedGuard = (props) => {
  const location = useLocation();
  const whiteList = ["/login", "/password/email", "/password/reset"];
  const isOnLoginPage = whiteList.includes(location.pathname);
  return isOnLoginPage || props.authUser ? (
    props.children
  ) : (
    <Redirect to={"/login"} />
  );
};

// export default AuthenticatedGuard;

const mapStateToProps = (state) => ({
  authUser: state.AuthUser.authUser,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(AuthenticatedGuard);
