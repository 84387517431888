import React, { Fragment } from "react";
import { connect } from "react-redux";
import Loader from "react-loaders";

class Loading extends React.Component {
  // constructor(props) {
  //   super(props);
  //   this.state = {};
  // }
  //
  // state = {};

  // render() {

  hello() {
    return <p>hello world!</p>
  }

  render() {
    return (
      this.hello()
    )
  }
    
    // loading = (
    //     <div className="loader-container">
    //       <div className="loader-container-inner">
    //         <div className="text-center">
    //           <Loader type="ball-pulse-rise"/>
    //         </div>
    //         <h6 className="mt-5">
    //           <small>ARE YOU WIVID ..?</small>
    //         </h6>
    //       </div>
    //     </div>
    // );
  // }
}

// const mapStateToProps = (state) => ({});
// const mapDispatchToProps = (dispatch) => ({});
// export default connect(mapStateToProps, mapDispatchToProps)(Loading);
export default Loading;