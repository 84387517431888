import "./polyfills";
import React from "react";
import ReactDOM from "react-dom";

import * as serviceWorker from "./serviceWorker";

import { HashRouter, BrowserRouter } from "react-router-dom";
import "./assets/base.scss";
import Main from "./DemoPages/Main";
import AuthenticatedGuard from "./DemoPages/Main/AuthenticatedGuard";
import { Provider } from "react-redux";
// import configureStore from "./config/configureStore";
import { PersistGate } from "redux-persist/integration/react";
import configureStore, { persistor } from "./configureStore";

import axios from "axios";

const store = configureStore;
const rootElement = document.getElementById("root");

axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";
axios.defaults.withCredentials = true;

const renderApp = (Component) => {
  ReactDOM.render(
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <HashRouter basename="/">
          <AuthenticatedGuard>
            <Component />
          </AuthenticatedGuard>
        </HashRouter>
      </PersistGate>
    </Provider>,
    rootElement
  );
};

renderApp(Main);

if (module.hot) {
  module.hot.accept("./DemoPages/Main", () => {
    const NextApp = require("./DemoPages/Main").default;
    renderApp(NextApp);
  });
}
serviceWorker.unregister();
