// actions
export const SET_SELECT_OPTIONS = "SET_SELECT_OPTIONS";
export const setSelectOptions = (selectOptions) => {
  return {
    type: SET_SELECT_OPTIONS,
    selectOptions,
  };
};

// reducer options
export default function reducer(
  state = {
    selectOptions: {
      areas: [],
      genders: [],
      progressionPhases: [],
      progressionStatuses: [],
      statusContracts: [],
      statusUserOperations: [],
      univs: [],
      univFaculties: [],
      univDepartments: [],
      wvBelongs: [],
      wvDivisions: [],
      wvPositions: [],
    },
  },
  action
) {
  switch (action.type) {
    case SET_SELECT_OPTIONS:
      return {
        // ...state,
        selectOptions: action.selectOptions,
      };
    default:
  }
  return state;
}
