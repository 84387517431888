// actions
export const SET_AUTH_USER = "SET_AUTH_USER";
export const setAuthUser = (authUser) => {
  return {
    type: SET_AUTH_USER,
    authUser,
  };
};

// reducer
export default function reducer(
  state = {
    authUser: null,
  },
  action
) {
  switch (action.type) {
    case SET_AUTH_USER:
      return {
        authUser: action.authUser,
      };
    default:
      console.log(action.type);
  }
  return state;
}
