import React, {Suspense, lazy, Fragment, useEffect} from "react";
import { Route, Redirect } from "react-router-dom";
import Loader from "react-loaders";
import Loading from "../Loading/Components/Loading";

import { ToastContainer } from "react-toastify";
import {connect, useDispatch, useSelector} from "react-redux";
import {setEnableMobileMenu} from "../../reducers/ThemeOptions";
import {setSelectOptions} from "../../reducers/SelectOptions";
import axios from "axios";

const Login = lazy(() => import("../../DemoPages/Login"));
const Logout = lazy(() => import("../../DemoPages/Logout"));
const PasswordEmail = lazy(() => import("../../DemoPages/Password/Email"));
const PasswordReset = lazy(() => import("../../DemoPages/Password/Reset"));
const Users = lazy(() => import("../../DemoPages/Users"));
const Members = lazy(() => import("../../DemoPages/Members"));
const Clients = lazy(() => import("../../DemoPages/Clients"));
const KeyFollows = lazy(() => import("../../DemoPages/KeyFollows"));
const Settings = lazy(() => import("../../DemoPages/Settings"));

const UserPages = lazy(() => import("../../DemoPages/UserPages"));
const Applications = lazy(() => import("../../DemoPages/Applications"));
const Dashboards = lazy(() => import("../../DemoPages/Dashboards"));

const Widgets = lazy(() => import("../../DemoPages/Widgets"));
const Elements = lazy(() => import("../../DemoPages/Elements"));
const Components = lazy(() => import("../../DemoPages/Components"));
const Charts = lazy(() => import("../../DemoPages/Charts"));
const Forms = lazy(() => import("../../DemoPages/Forms"));
const Tables = lazy(() => import("../../DemoPages/Tables"));


const AppMain = ( props ) => {


    useEffect(async () => {
      
      // try {
      //   const options = await axios.get(`${process.env.REACT_APP_API_URL}/options`);
      //   props.setSelectOptions(options.data.data);
      //  
      //   // Work with the response...
      // } catch (err) {
      //   if (err.response) {
      //     // The client was given an error response (5xx, 4xx)
      //     // console.log(err.response.data);
      //     // console.log(err.response.status);
      //     // console.log(err.response.headers);
      //  
      //   } else if (err.request) {
      //     // The client never received a response, and the request was never left
      //     console.log('no response');
      //  
      //   } else {
      //     // Anything else
      //     console.log('unknown error');
      //  
      //   }
      // }

      window.scrollTo(0, 0)
      
    }, []);

    
    return (
        <Fragment>

          {/* Auth */}
          <Suspense fallback={
            <div className="loader-container">
              <div className="loader-container-inner">
                <div className="text-center">
                  <Loader type="ball-pulse-rise"/>
                </div>
                <h6 className="mt-5">
                  ARE YOU VIVID ?
                  <small>Now loading...</small>
                </h6>
              </div>
            </div>
          }>
            <Route path="/login" component={Login}/>
            <Route path="/logout" component={Logout}/>
            <Route path="/password/email" component={PasswordEmail}/>
            <Route path="/password/reset" component={PasswordReset}/>
          </Suspense>


          {/* Members */}

            <Suspense fallback={
              <div className="loader-container">
                <div className="loader-container-inner">
                  <div className="text-center">
                    <Loader type="ball-pulse-rise"/>
                  </div>
                  <h6 className="mt-5">
                    ARE YOU VIVID ?
                    <small>Now loading...</small>
                  </h6>
                </div>
              </div>
              }>
                <Route path="/members" component={Members}/>
            </Suspense>

            {/* Clients */}

            <Suspense fallback={
              <div className="loader-container">
                <div className="loader-container-inner">
                  <div className="text-center">
                    <Loader type="ball-pulse-rise"/>
                  </div>
                  <h6 className="mt-5">
                    ARE YOU VIVID ?
                    <small>Now loading...</small>
                  </h6>
                </div>
              </div>
              }>
                <Route path="/clients" component={Clients}/>
            </Suspense>

          {/* Clients */}

          <Suspense fallback={
            <div className="loader-container">
              <div className="loader-container-inner">
                <div className="text-center">
                  <Loader type="ball-pulse-rise"/>
                </div>
                <h6 className="mt-5">
                  ARE YOU VIVID ?
                  <small>Now loading...</small>
                </h6>
              </div>
            </div>
          }>
            <Route path="/users" component={Users}/>
          </Suspense>

          {/* Clients */}

          <Suspense fallback={
            <div className="loader-container">
              <div className="loader-container-inner">
                <div className="text-center">
                  <Loader type="ball-pulse-rise"/>
                </div>
                <h6 className="mt-5">
                  ARE YOU VIVID ?
                  <small>Now loading...</small>
                </h6>
              </div>
            </div>
          }>
            <Route path="/key-follows" component={KeyFollows}/>
          </Suspense>

          {/* UserSpreadsheet */}

          <Suspense fallback={
            <div className="loader-container">
              <div className="loader-container-inner">
                <div className="text-center">
                  <Loader type="ball-pulse-rise"/>
                </div>
                <h6 className="mt-5">
                  ARE YOU VIVID ?
                  <small>Now loading...</small>
                </h6>
              </div>
            </div>
          }>
            <Route path="/settings" component={Settings}/>
          </Suspense>

          {/* Components */}

            <Suspense fallback={
                <div className="loader-container">
                    <div className="loader-container-inner">
                        <div className="text-center">
                            <Loader type="ball-pulse-rise"/>
                        </div>
                        <h6 className="mt-5">
                            Please wait while we load all the Components examples
                            <small>Because this is a demonstration we load at once all the Components examples. This wouldn't happen in a real live app!</small>
                        </h6>
                    </div>
                </div>
            }>
                <Route path="/components" component={Components}/>
            </Suspense>

            {/* Forms */}

            <Suspense fallback={
                <div className="loader-container">
                    <div className="loader-container-inner">
                        <div className="text-center">
                            <Loader type="ball-pulse-rise"/>
                        </div>
                        <h6 className="mt-5">
                            Please wait while we load all the Forms examples
                            <small>Because this is a demonstration we load at once all the Forms examples. This wouldn't happen in a real live app!</small>
                        </h6>
                    </div>
                </div>
            }>
                <Route path="/forms" component={Forms}/>
            </Suspense>

            {/* Charts */}

            <Suspense fallback={
                <div className="loader-container">
                    <div className="loader-container-inner">
                        <div className="text-center">
                            <Loader type="ball-rotate"/>
                        </div>
                        <h6 className="mt-3">
                            Please wait while we load all the Charts examples
                            <small>Because this is a demonstration we load at once all the Charts examples. This wouldn't happen in a real live app!</small>
                        </h6>
                    </div>
                </div>
            }>
                <Route path="/charts" component={Charts}/>
            </Suspense>

            {/* Tables */}

            <Suspense fallback={
                <div className="loader-container">
                    <div className="loader-container-inner">
                        <div className="text-center">
                            <Loader type="ball-pulse-rise"/>
                        </div>
                        <h6 className="mt-5">
                            Please wait while we load all the Tables examples
                            <small>Because this is a demonstration we load at once all the Tables examples. This wouldn't happen in a real live app!</small>
                        </h6>
                    </div>
                </div>
            }>
                <Route path="/tables" component={Tables}/>
            </Suspense>

            {/* Elements */}

            <Suspense fallback={
                <div className="loader-container">
                    <div className="loader-container-inner">
                        <div className="text-center">
                            <Loader type="line-scale"/>
                        </div>
                        <h6 className="mt-3">
                            Please wait while we load all the Elements examples
                            <small>Because this is a demonstration we load at once all the Elements examples. This wouldn't happen in a real live app!</small>
                        </h6>
                    </div>
                </div>
            }>
                <Route path="/elements" component={Elements}/>
            </Suspense>

            {/* Dashboard Widgets */}

            <Suspense fallback={
                <div className="loader-container">
                    <div className="loader-container-inner">
                        <div className="text-center">
                            <Loader type="ball-pulse-sync"/>
                        </div>
                        <h6 className="mt-3">
                            Please wait while we load all the Dashboard Widgets examples
                            <small>Because this is a demonstration we load at once all the Dashboard Widgets examples. This wouldn't happen in a real live app!</small>
                        </h6>
                    </div>
                </div>
            }>
                <Route path="/widgets" component={Widgets}/>
            </Suspense>

            {/* Pages */}

            <Suspense fallback={
                <div className="loader-container">
                    <div className="loader-container-inner">
                        <div className="text-center">
                            <Loader type="line-scale-party"/>
                        </div>
                        <h6 className="mt-3">
                            Please wait while we load all the Pages examples
                            <small>Because this is a demonstration we load at once all the Pages examples. This wouldn't happen in a real live app!</small>
                        </h6>
                    </div>
                </div>
            }>
                <Route path="/pages" component={UserPages}/>
            </Suspense>

            {/* Applications */}

            <Suspense fallback={
                <div className="loader-container">
                    <div className="loader-container-inner">
                        <div className="text-center">
                            <Loader type="ball-pulse"/>
                        </div>
                        <h6 className="mt-3">
                            Please wait while we load all the Applications examples
                            <small>Because this is a demonstration we load at once all the Applications examples. This wouldn't happen in a real live app!</small>
                        </h6>
                    </div>
                </div>
            }>
                <Route path="/apps" component={Applications}/>
            </Suspense>

            {/* Dashboards */}

            <Suspense fallback={
                <div className="loader-container">
                    <div className="loader-container-inner">
                        <div className="text-center">
                            <Loader type="ball-grid-cy"/>
                        </div>
                        <h6 className="mt-3">
                            Please wait while we load all the Dashboards examples
                            <small>Because this is a demonstration, we load at once all the Dashboards examples. This wouldn't happen in a real live app!</small>
                        </h6>
                    </div>
                </div>
            }>
                <Route path="/dashboards" component={Dashboards}/>
            </Suspense>


            <Route exact path="/" render={() => (
                <Redirect to="/dashboards/crm"/>
            )}/>
            <ToastContainer/>
        </Fragment>
    )
};

// export default AppMain;

const mapStateToProps = (state) => ({
  selectOptions: state.SelectOptions.selectOptions,
});

const mapDispatchToProps = (dispatch) => ({
  setSelectOptions: (data) => dispatch(setSelectOptions(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AppMain);

