import AuthUser from "./AuthUser";
import SelectOptions from "./SelectOptions";
import ThemeOptions from "./ThemeOptions";

const reducer = {
  AuthUser,
  SelectOptions,
  ThemeOptions,
};

export default reducer;
